import { LOGOUT_USER } from '../Auth/Auth.constants';
import {
  LISTER_DOCUMENTS,
  UPLAOD_DOCUMENTS,
  LISTER_TOUS_DOCUMENTS,
  RATTACHER_DOCUMENTS,
  DETACHER_DOCUMENTS
} from './Documents.constants';

function updateDocumentsState(oldData, docs, numero, type) {
  const newData = {
    ...oldData,
    ...{
      [numero]: oldData && oldData[numero] ? { ...oldData[numero], ...{ [type]: docs } } : { [type]: docs }
    }
  };
  return { list: newData };
}

function addDocumentsState(oldData, doc, numero, type) {
  const newData = {
    ...oldData,
    ...{
      [numero]:
        oldData && oldData[numero]
          ? {
              ...oldData[numero],
              ...{ [type]: [...oldData[numero][type], doc] }
            }
          : { [type]: [doc] }
    }
  };
  return { list: newData };
}

const initialState = {};

const DocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_DOCUMENTS:
      return {
        ...state,
        ...updateDocumentsState(state.list, action.data, action.numero, action.typeDocument)
      };
    case UPLAOD_DOCUMENTS:
      return {
        ...state,
        tous: undefined,
        ...addDocumentsState(state.list, action.data, action.numero, action.typeDocument)
      };

    case LISTER_TOUS_DOCUMENTS:
      return {
        ...state,
        tous: action.data
      };
    case RATTACHER_DOCUMENTS:
    case DETACHER_DOCUMENTS:
      return {
        ...state,
        tous: action.data,
        list: undefined
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

DocumentsReducer.hydrate = () => initialState;

export default DocumentsReducer;
