import { RECHERCHER_PLAN_ACTION, PLAN_ACTION_EN_RETARD } from './PlanAction.constants';
import { fetchApiAction } from '../../../setup/Api/api';
import { CLEAN_DASHBOARD_DATAS } from '../Reports/Dashboard.constants';

export function rechercherPlanAction(payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction('planAction:rechercher', { body: payload }, (result) => ({
        type: RECHERCHER_PLAN_ACTION,
        data: result,
        pageNumero: payload.pageNumero
      }))
    );
  };
}

export function enregistrerPlanAction(payload, filter) {
  return fetchApiAction(
    'planAction:enregistrer',
    { body: payload },
    () => planActionPostAction(filter),
    null,
    "Le plan d'action a été correctement enregistré."
  );
}

export function compterPlanActionRetard() {
  return (dispatch) => {
    dispatch(
      fetchApiAction('planAction:retard', {}, (result) => ({
        type: PLAN_ACTION_EN_RETARD,
        data: result
      }))
    );
  };
}

export function supprimerPlanAction(numero, filter) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'planAction:supprimer',
        { url: numero },
        () => planActionPostAction(filter),
        null,
        "Le plan d'action a été correctement supprimé."
      )
    );
  };
}

function planActionPostAction(filter) {
  return (dispatch) => {
    dispatch({ type: CLEAN_DASHBOARD_DATAS });
    dispatch(rechercherPlanAction(filter));
    dispatch(compterPlanActionRetard());
  };
}
