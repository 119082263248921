export const LISTER_RESSOURCES_HUMAINES = 'rh/LISTER_RESSOURCES_HUMAINES';
export const CREER_RESSOURCES_HUMAINES = 'rh/CREER_RESSOURCES_HUMAINES';
export const MODIFIER_RESSOURCES_HUMAINES = 'rh/MODIFIER_RESSOURCES_HUMAINES';
export const UPDATE_RESSOURCES_HUMAINES = 'rh/UPDATE_RESSOURCES_HUMAINES';
export const LIRE_ORGANIGRAMME = 'rh/LIRE_ORGANIGRAMME';
export const TOGGLE_NODE = 'rh/TOGGLE_NODE';

export const defaultRessourceHumaine = {
  civiliteCode: '',
  prenom: '',
  nom: '',
  numeroMatricule: '',
  nbAnneeExperience: '',
  diplome: '',
  naissanceDate: '',
  fichePoste: {},
  type: 'personnePhysique'
};
