import { LOGOUT_USER } from '../Auth/Auth.constants';
import { CLEAN_DASHBOARD_DATAS } from '../Reports/Dashboard.constants';
import {
  LISTER_ARBORESCENCE_PROCESSUS,
  ENREGISTRER_PROCESSUS,
  SUPPRIMER_PROCESSUS,
  TOGGLE_NODE,
  LISTER_ENTITE,
  RISQUE_ENREGISTRER,
  RISQUE_LISTER,
  RISQUE_SUPPRIMER,
  RISQUE_RECHERCHER,
  GENERER_FICHE_RISQUE,
  LISTER_TYPE_DMR
} from './Risque.constants';

const initialState = { pageNumero: 0 };

const RisqueReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_ARBORESCENCE_PROCESSUS:
    case ENREGISTRER_PROCESSUS:
    case SUPPRIMER_PROCESSUS:
    case TOGGLE_NODE:
      return {
        ...state,
        arborescenceData: action.data
      };
    case LISTER_ENTITE:
      return {
        ...state,
        entites: action.data
      };
    case RISQUE_LISTER:
      return {
        ...state,
        data: action.data
      };
    case LISTER_TYPE_DMR:
      return {
        ...state,
        typeDmr: action.data
      };
    case RISQUE_RECHERCHER:
      return {
        ...state,
        list: {
          ...action.data,
          content: action.pageNumero !== 0 ? [...state.list.content, ...action.data.content] : action.data.content
        },
        pageNumero: action.pageNumero
      };
    case RISQUE_ENREGISTRER:
    case RISQUE_SUPPRIMER:
      return {
        ...state,
        list: undefined
      };
    case GENERER_FICHE_RISQUE:
      return {
        ...state,
        fiche: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        list: undefined,
        pageNumero: 0
      };
    default:
      return state;
  }
};

RisqueReducer.hydrate = () => initialState;

export default RisqueReducer;
