import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx, src }) {
  return <Box component="img" alt="logo" src={src || '/static/brand/logo_full.png'} sx={{ height: 48, ...sx }} />;
}

Logo.propTypes = {
  src: PropTypes.any,
  sx: PropTypes.any
};
