// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  contact: '/contact-us',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, ''),
    app: path(ROOTS_DASHBOARD, '/app')
  },
  ressourcesHumaines: path(ROOTS_DASHBOARD, '/rh'),
  actifs: path(ROOTS_DASHBOARD, '/actifs'),
  risques: path(ROOTS_DASHBOARD, '/risques'),
  planAction: path(ROOTS_DASHBOARD, '/planAction'),
  utilisateurs: path(ROOTS_DASHBOARD, '/utilisateurs'),
  structures: path(ROOTS_DASHBOARD, '/structures'),
  parametres: path(ROOTS_DASHBOARD, '/parametres'),
  processus: {
    root: path(ROOTS_DASHBOARD, '/processus'),
    detail: path(ROOTS_DASHBOARD, '/processus/:numero')
  },
  procedures: {
    root: path(ROOTS_DASHBOARD, '/procedures'),
    detail: path(ROOTS_DASHBOARD, '/procedures/:numero')
  }
};

export const PATH_DOCS = 'https://candyde.click/';
