import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Card, CardHeader, CardContent } from '@material-ui/core';
import FileLoader from './FileLoader';
import { uploadLogo } from '../../redux/slices/Documents/Documents.actions';

export default function LogoForm({ handleClose }) {
  const [url, setUrl] = useState('');
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  function handleDrop(file) {
    setFile(file);
    setUrl(URL.createObjectURL(file));
  }

  function onLoad() {
    const fd = new FormData();
    fd.append('file', file);
    dispatch(uploadLogo(fd));
    setFile(file);
    setUrl('');
    handleClose();
  }

  return (
    <Card sx={{ with: '100%', backgroundColor: 'neutral' }}>
      <CardHeader title="Changer de logo" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FileLoader url={url} file={file} handleDrop={handleDrop} disabled={false} />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              size="large"
              variant="contained"
              style={{ width: 100, margin: 5 }}
              onClick={() => {
                handleClose();
              }}
            >
              Annuler
            </Button>

            <Button onClick={onLoad} disabled={!url} variant="contained" size="large">
              Charger
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

LogoForm.propTypes = {
  handleClose: PropTypes.func
};
