import { CREER_UTILISATEUR, LOGOUT_USER } from '../Auth/Auth.constants';
import {
  LISTER_RESSOURCES_HUMAINES,
  CREER_RESSOURCES_HUMAINES,
  MODIFIER_RESSOURCES_HUMAINES,
  UPDATE_RESSOURCES_HUMAINES,
  LIRE_ORGANIGRAMME,
  TOGGLE_NODE
} from './RH.constants';

const initialState = {};

const RHReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_RESSOURCES_HUMAINES:
    case CREER_RESSOURCES_HUMAINES:
    case MODIFIER_RESSOURCES_HUMAINES:
    case UPDATE_RESSOURCES_HUMAINES:
      return {
        ...state,
        data: action.data
      };
    case LIRE_ORGANIGRAMME:
    case TOGGLE_NODE:
      return {
        ...state,
        organigramme: action.data
      };
    case CREER_UTILISATEUR:
      return {
        ...state,
        data: undefined
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

RHReducer.hydrate = () => initialState;

export default RHReducer;
