export const LISTER_ARBORESCENCE_PROCESSUS = 'processus/LISTER_ARBORESCENCE_PROCESSUS';
export const SUPPRIMER_PROCESSUS = 'processus/SUPPRIMER_PROCESSUS';
export const ENREGISTRER_PROCESSUS = 'processus/ENREGISTRER_PROCESSUS';
export const TOGGLE_NODE = 'processus/TOGGLE_NODE';

export const LISTER_ENTITE = 'risque/LISTER_ENTITE';
export const RISQUE_ENREGISTRER = 'risque/RISQUE_ENREGISTRER';
export const RISQUE_LISTER = 'risque/RISQUE_LISTER';
export const RISQUE_RECHERCHER = 'risque/RISQUE_RECHERCHER';
export const RISQUE_SUPPRIMER = 'risque/RISQUE_SUPPRIMER';
export const GENERER_FICHE_RISQUE = 'risque/GENERER_FICHE_RISQUE';
export const LISTER_TYPE_DMR = 'risque/LISTER_TYPE_DMR';

export const DEFAULT_RISQUE_FROM = {
  entite: '',
  libelle: '',
  typeRisque: { id: '' },
  autreDispositifDMR: '',
  descriptionCause: '',
  descriptionConsequence: '',
  echelleGraviteRisqueInherent: '',
  echelleProbabiliteRisqueInherent: '',
  libelleControle: '',
  maturite: { id: '' },
  natureCauseRisque: { id: '' },
  niveauAcceptationRisque: { id: '' },
  typeConsequenceRisque: { id: '' },
  typeControleRisque: { code: '' },
  echelleControleRisqueInherent: '',
  echelleControleRisqueResiduel: '',
  echelleGraviteRisqueResiduel: '',
  echelleProbabiliteRisqueResiduel: '',
  planAction: '',
  referenceEntite: '',
  dateFinCible: '',
  dateDebut: ''
};

export const defaultFiltre = {};
