export const LISTER_TYPES_PROCESSUS = 'typesProcessus/LISTER_TYPES_PROCESSUS';
export const SUPPRIMER_TYPES_PROCESSUS = 'typesProcessus/SUPPRIMER_TYPES_PROCESSUS';
export const ENREGISTRER_TYPES_PROCESSUS = 'typesProcessus/ENREGISTRER_TYPES_PROCESSUS';

export const LISTER_FICHE_POSTE = 'fichePoste/LISTER_FICHE_POSTE';
export const ENREGISTRER_FICHE_POSTE = 'fichePoste/ENREGISTRER_FICHE_POSTE';
export const SUPPRIMER_FICHE_POSTE = 'fichePoste/SUPPRIMER_FICHE_POSTE';

export const LISTER_ARBORESCENCE_PALIER = 'palier/LISTER_ARBORESCENCE_PALIER';
export const ENREGISTRER_PALIER = 'palier/ENREGISTRER_PALIER';
export const UPDATE_PALIER = 'palier/UPDATE_PALIER';
export const SUPPRIMER_PALIER = 'palier/SUPPRIMER_PALIER';
export const LISTER_PALIERS = 'palier/LISTER_PALIERS';
export const TOGGLE_NODE = 'palier/TOGGLE_NODE';

export const SUPPRIMER_ACTEUR = 'acteur/SUPPRIMER_ACTEUR';
export const ENREGISTRER_ACTEUR = 'acteur/ENREGISTRER_ACTEUR';
export const LISTER_ACTEURS = 'acteur/LISTER_ACTEURS';

export const LISTER_PARAMETRES = 'parametrage/LISTER_PARAMETRES';
export const SUPPRIMER_PARAMETRES = 'parametrage/SUPPRIMER_PARAMETRES';
export const ENREGISTRER_PARAMETRES = 'parametrage/ENREGISTRER_PARAMETRES';

export const LIRE_PARAMETRES_MANUEL = 'parametrage/LIRE_PARAMETRES_MANUEL';
export const ENREGISTRER_PARAMETRES_MANUEL = 'parametrage/ENREGISTRER_PARAMETRES_MANUEL';

export const TYPES_TABS = [
  { value: 'ECHELLE_CONTROLE', label: 'Echelle contrôle' },
  { value: 'ECHELLE_GRAVITE', label: 'Echelle gravite' },
  { value: 'ECHELLE_PROBABILITE', label: 'Echelle probabilite' },
  /*  { value: 'MATURITE_RISQUE', label: 'Maturite risque' }, */
  { value: 'NATURE_CAUSE_RISQUE', label: 'Nature cause risque' },
  { value: 'NIVEAU_ACCEPTATION_RISQUE', label: 'Niveau acceptation risque' },
  { value: 'TYPE_CONSEQUENCE_RISQUE', label: 'Type consequence risque' },
  { value: 'TYPE_CONTROLE_RISQUE', label: 'Type de dispositif de maitrise des risques DMR' },
  { value: 'TYPE_RISQUE', label: 'Type risque' }
];

export const LIST_STYLE_FAMILY = [
  'Arial',
  'Arial Black',
  'Bahnschrift',
  'Calibri',
  'Cambria',
  'Cambria Math',
  'Candara',
  'Comic Sans MS',
  'Consolas',
  'Constantia',
  'Corbel',
  'Courier',
  'Courier New',
  'Ebrima',
  'Franklin Gothic Medium',
  'Gabriola',
  'Gadugi',
  'Georgia',
  'HoloLens MDL2 Assets',
  'Impact',
  'Ink Free',
  'Javanese Text',
  'Leelawadee UI',
  'Lucida Console',
  'Lucida Sans Unicode',
  'Malgun Gothic',
  'Marlett',
  'Microsoft Himalaya',
  'Microsoft JhengHei',
  'Microsoft New Tai Lue',
  'Microsoft PhagsPa',
  'Microsoft Sans Serif',
  'Microsoft Tai Le',
  'Microsoft YaHei',
  'Microsoft Yi Baiti',
  'MingLiU-ExtB',
  'Mongolian Baiti',
  'MS Gothic',
  'MV Boli',
  'Myanmar Text',
  'Nirmala UI',
  'Palatino Linotype',
  'Segoe MDL2 Assets',
  'Segoe Print',
  'Segoe Script',
  'Segoe UI',
  'Segoe UI Historic',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'SimSun',
  'Sitka',
  'Sylfaen',
  'Symbol',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
  'Webdings',
  'Wingdings',
  'Yu Gothic'
];
