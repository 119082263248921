import { UPDATE_DASHBOARD_DATAS, CLEAN_DASHBOARD_DATAS } from './Dashboard.constants';
import { LOGOUT_USER } from '../Auth/Auth.constants';
import { UPDATE_PROCESSUS } from '../Processus/Processus.constants';

const initialState = {};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_DATAS:
      return {
        ...state,
        data: action.data
      };
    case UPDATE_PROCESSUS:
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        data: undefined
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

DashboardReducer.hydrate = () => initialState;

export default DashboardReducer;
