import { LOGOUT_USER } from '../Auth/Auth.constants';
import {
  LISTER_TYPES_PROCESSUS,
  ENREGISTRER_TYPES_PROCESSUS,
  SUPPRIMER_TYPES_PROCESSUS,
  LISTER_FICHE_POSTE,
  ENREGISTRER_FICHE_POSTE,
  SUPPRIMER_FICHE_POSTE,
  LISTER_ARBORESCENCE_PALIER,
  ENREGISTRER_PALIER,
  SUPPRIMER_PALIER,
  UPDATE_PALIER,
  LISTER_PALIERS,
  TOGGLE_NODE,
  SUPPRIMER_ACTEUR,
  ENREGISTRER_ACTEUR,
  LISTER_ACTEURS,
  LISTER_PARAMETRES,
  SUPPRIMER_PARAMETRES,
  ENREGISTRER_PARAMETRES,
  LIRE_PARAMETRES_MANUEL,
  ENREGISTRER_PARAMETRES_MANUEL
} from './Parametres.constants';

const initialState = {};

const ParametresReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_TYPES_PROCESSUS:
    case ENREGISTRER_TYPES_PROCESSUS:
    case SUPPRIMER_TYPES_PROCESSUS:
      return {
        ...state,
        typesProcessusData: action.data
      };
    case LISTER_FICHE_POSTE:
    case ENREGISTRER_FICHE_POSTE:
    case SUPPRIMER_FICHE_POSTE:
      return {
        ...state,
        fichesPosteData: action.data
      };
    case LISTER_ARBORESCENCE_PALIER:
    case ENREGISTRER_PALIER:
    case SUPPRIMER_PALIER:
    case UPDATE_PALIER:
    case TOGGLE_NODE:
      console.log('changess', action.data);
      return {
        ...state,
        arborescencePalierData: action.data,
        palierData: undefined
      };
    case LISTER_PALIERS:
      return {
        ...state,
        palierData: action.data
      };
    case LIRE_PARAMETRES_MANUEL:
    case ENREGISTRER_PARAMETRES_MANUEL:
      return {
        ...state,
        paramManuel: action.data
      };
    case SUPPRIMER_ACTEUR:
    case ENREGISTRER_ACTEUR:
    case LISTER_ACTEURS:
      return {
        ...state,
        acteurs: action.data
      };
    case LISTER_PARAMETRES:
    case SUPPRIMER_PARAMETRES:
    case ENREGISTRER_PARAMETRES:
      return {
        ...state,
        risqueData: { ...state.risqueData, [action.typeParametreRisque]: action.data }
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

ParametresReducer.hydrate = () => initialState;

export default ParametresReducer;
