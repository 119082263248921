export const ENREGISTRER_PLAN_ACTION = 'planAction/ENREGISTRER_PLAN_ACTION';
export const PLAN_ACTION_EN_RETARD = 'planAction/PLAN_ACTION_EN_RETARD';
export const SUPPRIMER_PLAN_ACTION = 'planAction/SUPPRIMER_PLAN_ACTION';
export const RECHERCHER_PLAN_ACTION = 'planAction/RECHERCHER_PLAN_ACTION';

// ------------------------------------------------------------
export const defaultFiltre = {
  critere: '',
  statut: '',
  dateDebutMin: '',
  dateDebutMax: '',
  numeroResponsable: ''
};
