import { LOGOUT_USER } from '../Auth/Auth.constants';
import { RECHERCHER_PLAN_ACTION, PLAN_ACTION_EN_RETARD } from './PlanAction.constants';

const initialState = { pageNumero: 0 };

const PlanActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECHERCHER_PLAN_ACTION:
      return {
        ...state,
        list: {
          ...action.data,
          content: action.pageNumero !== 0 ? [...state.list.content, ...action.data.content] : action.data.content
        },
        pageNumero: action.pageNumero
      };
    case PLAN_ACTION_EN_RETARD:
      return {
        ...state,
        nbRetard: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

PlanActionReducer.hydrate = () => initialState;

export default PlanActionReducer;
