import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link, Stack, Alert, TextField, IconButton, InputAdornment, MenuItem } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { isTokenValid, isForceChangePassword, getAutreStructure } from '../../../redux/slices/Auth/Auth.selectors';
import { loginUser } from '../../../redux/slices/Auth/Auth.actions';
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: { borderColor: theme.palette.primary.main },
  notchedOutline: {
    borderColor: theme.palette.primary.main
  }
}));

// ----------------------------------------------------------------------
LoginForm.propTypes = {
  tokenValid: PropTypes.func,
  isForceChangePassword: PropTypes.func,
  loginUser: PropTypes.func,
  autreStructure: PropTypes.object
};

export default function LoginForm() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth);
  const tokenValid = useSelector((state) => isTokenValid(state));
  const isForceChangePasswordState = useSelector((state) => isForceChangePassword(state));
  const autreStructure = useSelector((state) => getAutreStructure(state));
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [listStructure, setListStructure] = useState(undefined);

  useEffect(() => {
    if (user && tokenValid && !isForceChangePasswordState) {
      navigate(PATH_DASHBOARD.general.dashboard, { replace: true });
    } else if (isForceChangePasswordState) {
      navigate(PATH_AUTH.resetPassword, { replace: true });
    }
  });

  useEffect(() => {
    if (autreStructure) {
      setListStructure(autreStructure);
    }
  }, [autreStructure]);

  const LoginSchema = Yup.object().shape({
    identifiant: Yup.string().required("Veuillez saisir l'identifiant"),
    password: Yup.string().required('Veuillez saisir le mot de passe')
  });

  const formik = useFormik({
    initialValues: {
      identifiant: '',
      password: '',
      structureId: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(loginUser({ ...values }));
    }
  });

  const { errors, touched, values, handleSubmit, handleBlur, handleChange, getFieldProps, isValidating } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            name="identifiant"
            fullWidth
            autoComplete="Identifiant"
            type="text"
            label="Identifiant"
            onChange={handleChange}
            value={values.identifiant}
            {...getFieldProps('identifiant')}
            error={Boolean(touched.identifiant && errors.identifiant)}
            helperText={touched.identifiant && errors.identifiant}
          />

          <TextField
            name="password"
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            onChange={handleChange}
            value={values.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          {listStructure && (
            <TextField
              select
              // error={Boolean(touched.structureId)}
              fullWidth
              label="Structure de travail"
              name="structureId"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.structureId}
              variant="outlined"
              InputProps={{
                classes: {
                  root: classes.root,
                  notchedOutline: classes.notchedOutline
                }
              }}
              required
            >
              {listStructure.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {`${option.nom}`}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Stack>

        <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Mot de passe oublié ? &nbsp;
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={isValidating}>
          Se connecter
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
