import { getConfig } from '../platform';

const baseApi = getConfig('apiUrl');

// eslint-disable-next-line
export default {
  'utilisateurs:lister': {
    url: `${baseApi}/utilisateur/lister`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:creer': {
    url: `${baseApi}/utilisateur/creer`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:modifier': {
    url: `${baseApi}/utilisateur/modifier`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:creerstructure': {
    url: `${baseApi}/utilisateur/structure/creer`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:rattacherstructure': {
    url: `${baseApi}/utilisateur/structure/rattacher`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:listerstructure': {
    url: `${baseApi}/utilisateur/structure/lister`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:modifierstructure': {
    url: `${baseApi}/utilisateur/structure/modifier`,
    method: 'POST',
    secure: true
  },
  'utilisateurs:debloquer': {
    url: (id) => `${baseApi}/utilisateur/structure/debloquer/${id}`,
    method: 'GET',
    secure: true
  },
  'utilisateurs:bloquer': {
    url: (id) => `${baseApi}/utilisateur/structure/bloquer/${id}`,
    method: 'GET',
    secure: true
  },
  'auth:login': {
    url: `${baseApi}/utilisateur/connexion`,
    method: 'POST'
  },
  'auth:refreshToken': {
    url: (refreshToken) => `${baseApi}/utilisateur/refreshToken?refreshToken=${refreshToken}`,
    method: 'GET'
  },
  'auth:mdpOublie': {
    url: (mail) => `${baseApi}/utilisateur/mdpOublie?identifiant=${mail}`,
    method: 'GET'
  },
  'auth:modifierMdp': {
    url: `${baseApi}/utilisateur/modifierMdp`,
    method: 'POST',
    secure: true
  },
  'dashboard:lire': {
    url: `${baseApi}/dashboard/lire`,
    method: 'GET',
    secure: true
  },
  'codification:lister': {
    url: (code) => `${baseApi}/codifications/lister/${code}`,
    method: 'GET',
    secure: true
  },
  'rh:lister': {
    url: `${baseApi}/collaborateur/lister`,
    method: 'GET',
    secure: true
  },
  'rh:creer': {
    url: `${baseApi}/collaborateur/creer`,
    method: 'POST',
    secure: true
  },
  'rh:modifier': {
    url: `${baseApi}/collaborateur/modifier`,
    method: 'POST',
    secure: true
  },
  'rh:bloquer': {
    url: (param) => `${baseApi}/collaborateur/bloquer/${param.bloquer}/${param.id}`,
    method: 'GET',
    secure: true
  },

  'organigramme:lire': {
    url: `${baseApi}/collaborateur/organigramme/lire`,
    method: 'GET',
    secure: true
  },
  'typeProcessus:lister': {
    url: `${baseApi}/processus/typeProcessus/lister`,
    method: 'GET',
    secure: true
  },
  'typeProcessus:enregistrer': {
    url: `${baseApi}/processus/typeProcessus/enregistrer`,
    method: 'POST',
    secure: true
  },
  'typeProcessus:supprimer': {
    url: (numero) => `${baseApi}/processus/typeProcessus/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'arborescenceProcessus:lister': {
    url: `${baseApi}/processus/arborescence/lister`,
    method: 'GET',
    secure: true
  },
  'processus:enregistrer': {
    url: `${baseApi}/processus/enregistrer`,
    method: 'POST',
    secure: true
  },
  'processus:supprimer': {
    url: (numero) => `${baseApi}/processus/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'processus:lire': {
    url: (numero) => `${baseApi}/processus/lire/${numero}`,
    method: 'GET',
    secure: true
  },
  'processus:lister': {
    url: `${baseApi}/processus/lister`,
    method: 'GET',
    secure: true
  },
  'processus:alllister': {
    url: `${baseApi}/processus/all/lister`,
    method: 'GET',
    secure: true
  },
  'fichePoste:lister': {
    url: `${baseApi}/rh/fichePoste/lister`,
    method: 'GET',
    secure: true
  },
  'fichePoste:enregistrer': {
    url: `${baseApi}/rh/fichePoste/enregistrer`,
    method: 'POST',
    secure: true
  },
  'fichePoste:supprimer': {
    url: (numero) => `${baseApi}/rh/fichePoste/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },

  'arborescencePalier:lister': {
    url: `${baseApi}/rh/palier/arborescence/lister`,
    method: 'GET',
    secure: true
  },
  'palier:enregistrer': {
    url: `${baseApi}/rh/palier/enregistrer`,
    method: 'POST',
    secure: true
  },
  'palier:supprimer': {
    url: (numero) => `${baseApi}/rh/palier/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'palier:lister': {
    url: `${baseApi}/rh/palier/lister`,
    method: 'GET',
    secure: true
  },
  'actif:enregistrer': {
    url: `${baseApi}/actif/enregistrer`,
    method: 'POST',
    secure: true
  },
  'actif:supprimer': {
    url: (numero) => `${baseApi}/actif/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'actif:lister': {
    url: `${baseApi}/actif/lister`,
    method: 'GET',
    secure: true
  },
  'actif:rechercher': {
    url: `${baseApi}/actif/rechercher`,
    method: 'POST',
    secure: true
  },
  'documents:uploadLogo': {
    url: `${baseApi}/documents/uploadLogo`,
    method: 'POST',
    secure: true,
    fileUpload: true
  },
  'documents:upload': {
    url: `${baseApi}/documents/upload`,
    method: 'POST',
    secure: true,
    fileUpload: true
  },
  'documents:uploadVide': {
    url: `${baseApi}/documents/upload/vide`,
    method: 'POST',
    secure: true
  },
  'documents:lister': {
    url: `${baseApi}/documents/lister`,
    method: 'POST',
    secure: true
  },

  'documents:listerTous': {
    url: `${baseApi}/documents/tous/lister`,
    method: 'GET',
    secure: true
  },
  'documents:rattacher': {
    url: (param) => `${baseApi}/documents/rattacher/${param.id}/${param.cle}/${param.valeur}`,
    method: 'GET',
    secure: true
  },
  'documents:detacher': {
    url: (param) => `${baseApi}/documents/detacher/${param.id}/${param.cle}/${param.valeur}`,
    method: 'GET',
    secure: true
  },

  'regleGestion:lister': {
    url: (numeroProcessus) => `${baseApi}/rg/lister/${numeroProcessus}`,
    method: 'GET',
    secure: true
  },
  'regleGestion:enregistrer': {
    url: (numeroProcessus) => `${baseApi}/rg/enregistrer/${numeroProcessus}`,
    method: 'POST',
    secure: true
  },
  'regleGestion:supprimer': {
    url: (param) => `${baseApi}/rg/supprimer/${param.numeroProcessus}/${param.numero}`,
    method: 'DELETE',
    secure: true
  },
  'tache:lister': {
    url: (numeroProcessus) => `${baseApi}/tache/lister/${numeroProcessus}`,
    method: 'GET',
    secure: true
  },
  'tache:listerLiee': {
    url: (numeroProcessus) => `${baseApi}/tache/lister/liee/${numeroProcessus}`,
    method: 'GET',
    secure: true
  },
  'tache:enregistrer': {
    url: (numeroProcessus) => `${baseApi}/tache/enregistrer/${numeroProcessus}`,
    method: 'POST',
    secure: true
  },
  'tache:supprimer': {
    url: (param) => `${baseApi}/tache/supprimer/${param.numeroProcessus}/${param.numero}`,
    method: 'DELETE',
    secure: true
  },

  'acteur:enregistrer': {
    url: `${baseApi}/acteur/enregistrer`,
    method: 'POST',
    secure: true
  },
  'acteur:supprimer': {
    url: (id) => `${baseApi}/acteur/supprimer/${id}`,
    method: 'DELETE',
    secure: true
  },
  'acteur:lister': {
    url: `${baseApi}/acteur/lister`,
    method: 'GET',
    secure: true
  },

  'procedure:enregistrer': {
    url: (numero) => `${baseApi}/procedure/enregistrer/${numero}`,
    method: 'POST',
    secure: true
  },
  'procedure:lire': {
    url: (numero) => `${baseApi}/procedure/lire/${numero}`,
    method: 'GET',
    secure: true
  },
  'procedure:generer': {
    url: `${baseApi}/procedure/generer`,
    method: 'GET',
    secure: true
  },
  'procedure:rechercher': {
    url: `${baseApi}/procedure/rechercher`,
    method: 'POST',
    secure: true
  },

  'etape:lister': {
    url: (numeroProcessus) => `${baseApi}/etape/lister/${numeroProcessus}`,
    method: 'GET',
    secure: true
  },
  'etape:enregistrer': {
    url: (numeroProcessus) => `${baseApi}/etape/enregistrer/${numeroProcessus}`,
    method: 'POST',
    secure: true
  },
  'etape:supprimer': {
    url: (param) => `${baseApi}/etape/supprimer/${param.numeroProcessus}/${param.id}`,
    method: 'DELETE',
    secure: true
  },
  'parametrage:enregistrer': {
    url: (type) => `${baseApi}/parametrage/enregistrer/${type}`,
    method: 'POST',
    secure: true
  },
  'parametrage:lister': {
    url: (type) => `${baseApi}/parametrage/lister/${type}`,
    method: 'GET',
    secure: true
  },
  'parametrage:supprimer': {
    url: ({ type, id }) => `${baseApi}/parametrage/supprimer/${type}/${id}`,
    method: 'DELETE',
    secure: true
  },
  'entiteRisuqe:lister': {
    url: () => `${baseApi}/risque/entite/lister`,
    method: 'GET',
    secure: true
  },
  'risque:enregistrer': {
    url: () => `${baseApi}/risque/enregistrer`,
    method: 'POST',
    secure: true
  },
  'risque:lister': {
    url: () => `${baseApi}/risque/lister`,
    method: 'GET',
    secure: true
  },
  'risque:rechercher': {
    url: () => `${baseApi}/risque/rechercher`,
    method: 'POST',
    secure: true
  },
  'risque:supprimer': {
    url: (numero) => `${baseApi}/risque/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'planAction:enregistrer': {
    url: () => `${baseApi}/planAction/enregistrer`,
    method: 'POST',
    secure: true
  },
  'planAction:rechercher': {
    url: () => `${baseApi}/planAction/rechercher`,
    method: 'POST',
    secure: true
  },
  'planAction:retard': {
    url: () => `${baseApi}/planAction/enRetard`,
    method: 'GET',
    secure: true
  },
  'planAction:supprimer': {
    url: (numero) => `${baseApi}/planAction/supprimer/${numero}`,
    method: 'DELETE',
    secure: true
  },
  'risque:generer': {
    url: `${baseApi}/risque/generer`,
    method: 'GET',
    secure: true
  },
  'risque:TypeDmr': {
    url: `${baseApi}/risque/typeDmr/lister`,
    method: 'GET',
    secure: true
  },
  'paramManuel:enregistrer': {
    url: `${baseApi}/parametrage/manuel/enregistrer`,
    method: 'POST',
    secure: true
  },
  'paramManuel:lire': {
    url: `${baseApi}/parametrage/manuel/lire`,
    method: 'GET',
    secure: true
  }
};
