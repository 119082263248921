import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      {
        title: 'tableau de bord',
        path: PATH_DASHBOARD.general.app,
        icon: <Icon icon="ant-design:fund-projection-screen-outlined" width="2048" height="2048" />
      },
      {
        title: 'ressources humaines',
        path: PATH_DASHBOARD.ressourcesHumaines,
        icon: <Icon icon="carbon:user-multiple" width="2048" height="2048" />
      },
      {
        title: 'actifs',
        path: PATH_DASHBOARD.actifs,
        icon: <Icon icon="ant-design:file-protect-outlined" width="2048" height="2048" />
      },
      {
        title: 'processus',
        path: PATH_DASHBOARD.processus.root,
        icon: <Icon icon="uis:process" width="2048" height="2048" />
      },
      {
        title: 'procedures',
        path: PATH_DASHBOARD.procedures.root,
        icon: <Icon icon="ph:tree-structure-fill" width="2048" height="2048" />
      },
      {
        title: 'gestion des risques',
        path: PATH_DASHBOARD.risques,
        icon: <Icon icon="carbon:checkmark-outline-warning" width="2048" height="2048" />
      },
      {
        title: "plans d'actions",
        path: PATH_DASHBOARD.planAction,
        icon: <Icon icon="ic:round-pending-actions" width="2048" height="2048" />,
        badge: true
      }
    ]
  },

  // UTILISATEURS
  // {
  //   subheader: 'Structures',
  //   items: [
  //     // {
  //     //   title: 'utilisateurs',
  //     //   path: PATH_DASHBOARD.utilisateurs,
  //     //   icon: <Icon icon="bxs:user-pin" width="2048" height="2048" />,
  //     //   admin: true
  //     // },
  //     {
  //       title: 'structures',
  //       path: PATH_DASHBOARD.structures,
  //       icon: <Icon icon="bi:card-list" width="2048" height="2048" />,
  //       superAdmin: true
  //     }
  //   ]
  // },

  // SETTINGS
  {
    subheader: 'parametres',
    items: [
      {
        title: 'parametres',
        path: PATH_DASHBOARD.parametres,
        icon: <Icon icon="ci:settings" width="2048" height="2048" />,
        admin: true
      },
      {
        title: 'structures',
        path: PATH_DASHBOARD.structures,
        icon: <Icon icon="bi:card-list" width="2048" height="2048" />,
        superAdmin: true
      }
    ]
  }
];

export default sidebarConfig;
