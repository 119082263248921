export const UPDATE_ACTIF = 'actif/UPDATE_ACTIF';
export const LISTER_ACTIF = 'actif/LISTER_ACTIF';
export const RECHERCHER_ACTIF = 'actif/RECHERCHER_ACTIF';

// ------------------------------------------------------------
export const defaultFiltre = {
  critere: '',
  valeur: '',
  type: ''
};

export const defaultActif = {
  intitule: '',
  valeurCode: '',
  typeActifCode: '',
  idRessource: ''
};
