import { LOGOUT_USER } from '../Auth/Auth.constants';
import { UPDATE_ACTIF, LISTER_ACTIF, RECHERCHER_ACTIF } from './Actif.constants';

const initialState = { pageNumero: 0 };

const ActifReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIF:
    case LISTER_ACTIF:
      return {
        ...state,
        data: action.data
      };
    case RECHERCHER_ACTIF:
      return {
        ...state,
        list: {
          ...action.data,
          content: action.pageNumero !== 0 ? [...state.list.content, ...action.data.content] : action.data.content
        },
        pageNumero: action.pageNumero
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

ActifReducer.hydrate = () => initialState;

export default ActifReducer;
