import { LOGOUT_USER } from '../Auth/Auth.constants';
import { CLEAN_DASHBOARD_DATAS } from '../Reports/Dashboard.constants';
import {
  LISTER_ARBORESCENCE_PROCESSUS,
  UPDATE_PROCESSUS,
  LISTER_PROCESSUS,
  LIRE_PROCESSUS,
  TOGGLE_NODE,
  LISTER_REGLE_GESTION,
  ENREGISTRER_REGLE_GESTION,
  SUPPRIMER_REGLE_GESTION,
  LISTER_TACHES,
  ENREGISTRER_TACHE,
  SUPPRIMER_TACHE,
  LIRE_GENERAL,
  ENREGISTRER_GENERAL,
  GENERER_MANUEL_PROCEDURE,
  LISTER_TACHES_LIEE,
  ENREGISTRER_ETAPE,
  LISTER_ETAPE,
  SUPPRIMER_ETAPE,
  RECHERCHER_PROCEDURE_GENERAL,
  LISTER_PROCESSUS_SOUS_PROCESSUS
} from './Processus.constants';

function updateProcessus(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { processus: newData };
}

function updateEtapesState(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { etapes: newData };
}

function updateRegleGestionState(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { reglesGestion: newData };
}

function updateTachesState(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { taches: newData };
}

function updateTachesLieeState(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { tachesLiee: newData };
}

function updateProcedureState(oldData, data, numero) {
  const newData = { ...oldData, ...{ [numero]: data } };
  return { procedures: newData };
}

const initialState = { pageNumero: 0 };

const ProcessusReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_ARBORESCENCE_PROCESSUS:
    case TOGGLE_NODE:
      return {
        ...state,
        arborescenceData: action.data
      };
    case UPDATE_PROCESSUS:
      return {
        ...state,
        arborescenceData: action.data,
        processus: undefined,
        liste: undefined,
        result: undefined
      };
    case LISTER_PROCESSUS:
      return {
        ...state,
        liste: action.data
      };
    case LISTER_PROCESSUS_SOUS_PROCESSUS:
      return {
        ...state,
        listeAll: action.data
      };
    case RECHERCHER_PROCEDURE_GENERAL:
      return {
        ...state,
        result: {
          ...action.data,
          content: action.pageNumero !== 0 ? [...state.result.content, ...action.data.content] : action.data.content
        },
        pageNumero: action.pageNumero
      };
    case LIRE_PROCESSUS:
      return {
        ...state,
        ...updateProcessus(state.processus, action.data, action.numero)
      };
    case LISTER_REGLE_GESTION:
    case ENREGISTRER_REGLE_GESTION:
    case SUPPRIMER_REGLE_GESTION:
      return {
        ...state,
        ...updateRegleGestionState(state.reglesGestion, action.data, action.numeroProcessus)
      };

    case ENREGISTRER_ETAPE:
    case LISTER_ETAPE:
    case SUPPRIMER_ETAPE:
      return {
        ...state,
        ...updateEtapesState(state.etapes, action.data, action.numeroProcessus)
      };
    case LIRE_GENERAL:
    case ENREGISTRER_GENERAL:
      return {
        ...state,
        ...updateProcedureState(state.procedures, action.data, action.numeroProcessus)
      };
    case LISTER_TACHES:
      return {
        ...state,
        ...updateTachesState(state.taches, action.data, action.numeroProcessus)
      };
    case ENREGISTRER_TACHE:
    case SUPPRIMER_TACHE:
      return {
        ...state,
        ...updateTachesState(state.taches, action.data, action.numeroProcessus),
        ...updateTachesLieeState(state.tachesLiee, undefined, action.numeroProcessus)
      };
    case LISTER_TACHES_LIEE:
      return {
        ...state,
        ...updateTachesLieeState(state.tachesLiee, action.data, action.numeroProcessus)
      };
    case GENERER_MANUEL_PROCEDURE:
      return {
        ...state,
        manuel: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    case CLEAN_DASHBOARD_DATAS:
      return {
        ...state,
        liste: undefined,
        result: undefined,
        pageNumero: 0
      };
    default:
      return state;
  }
};

ProcessusReducer.hydrate = () => initialState;

export default ProcessusReducer;
