import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import ApiReducer from './slices/Api/Api.reducer';
import AuthReducer from './slices/Auth/Auth.reducer';
import DashboardReducer from './slices/Reports/Dashboard.reducer';
import RHReducer from './slices/RH/RH.reducer';
import ParametresReducer from './slices/Settings/Parametres.reducer';
import ProcessusReducer from './slices/Processus/Processus.reducer';
import ActifReducer from './slices/Actifs/Actif.reducer';
import DocumentsReducer from './slices/Documents/Documents.reducer';
import RisqueReducer from './slices/Risques/Risque.reducer';
import PlanActionReducer from './slices/PlanAction/PlanAction.reducer';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  api: ApiReducer,
  auth: AuthReducer,
  dashboard: DashboardReducer,
  ressourcesHumaines: RHReducer,
  parametres: ParametresReducer,
  processus: ProcessusReducer,
  actif: ActifReducer,
  documents: DocumentsReducer,
  risques: RisqueReducer,
  planAction: PlanActionReducer
});

export { rootPersistConfig, rootReducer };
