import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Card from '@material-ui/core/Card';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { colors } from '@material-ui/core';

const classes = {
  root: {
    width: '100%',
    backgroundColor: '#F4E8E8'
  },
  dropzone: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#F4E8E8',
    borderRadius: 5,
    height: '100%',
    textAlign: 'center',
    maxHeight: '95px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropzoneDisable: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#FBFBFB',
    borderRadius: 5,
    height: '100%',
    textAlign: 'center',
    maxHeight: '95px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropZoneLabel: {
    verticalAlign: 'middle',
    margin: 5,
    color: colors.grey[400],
    fontStyle: 'italic',
    textAlign: 'center'
  },
  fichier: {
    marginTop: 20,
    textAlign: 'center'
  },
  buttonInput: {
    disabled: 'flex',
    textAlign: 'center'
  }
};

FileLoader.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  url: PropTypes.string,
  file: PropTypes.any
};

export default function FileLoader({ url, file, handleDrop }) {
  const [fileUrl, setFileUrl] = useState(file ? URL.createObjectURL(file) : url);
  const maxSize = 104857600;

  function handleDropFile(files) {
    setFileUrl(URL.createObjectURL(files[0]));
    handleDrop(files[0]);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Dropzone
          className={classes.dropzone}
          maxSize={maxSize}
          maxFiles={1}
          onDrop={(acceptedFiles) => handleDropFile(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography className={classes.dropZoneLabel}>
                  Glissez / déposez un fichier ici, ou cliquez ici pour en choisir
                </Typography>
              </div>
            </section>
          )}
        </Dropzone>

        {fileUrl && (
          <Typography component="div" className={classes.fichier}>
            <PictureAsPdfIcon style={{ margin: 10, color: 'red' }} />
            {fileUrl}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
