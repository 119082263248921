import {
  LISTER_DOCUMENTS,
  UPLAOD_DOCUMENTS,
  UPLAOD_LOGO,
  LISTER_TOUS_DOCUMENTS,
  RATTACHER_DOCUMENTS,
  DETACHER_DOCUMENTS
} from './Documents.constants';
import { fetchApiAction } from '../../../setup/Api/api';

export function listerDocuments(type, numero, payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction('documents:lister', { body: payload }, (result) => ({
        type: LISTER_DOCUMENTS,
        data: result,
        numero,
        typeDocument: type
      }))
    );
  };
}

export function uploadDocument(type, numero, payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:upload',
        { body: payload },
        (result) => ({
          type: UPLAOD_DOCUMENTS,
          data: result,
          numero,
          typeDocument: type
        }),
        null,
        'Le document a été correctement enregistré.'
      )
    );
  };
}

export function uploadDocumentVide(type, numero, payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:uploadVide',
        { body: payload },
        (result) => ({
          type: UPLAOD_DOCUMENTS,
          data: result,
          numero,
          typeDocument: type
        }),
        null,
        'Le document a été correctement enregistré.'
      )
    );
  };
}

export function uploadLogo(payload) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:uploadLogo',
        { body: payload },
        (result) => ({
          type: UPLAOD_LOGO,
          data: result
        }),
        null,
        'Le logo a été correctement chargé.'
      )
    );
  };
}

export function listerTousDocuments() {
  return (dispatch) => {
    dispatch(
      fetchApiAction('documents:listerTous', {}, (result) => ({
        type: LISTER_TOUS_DOCUMENTS,
        data: result
      }))
    );
  };
}

export function rattacherDocument(id, type, numero) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:rattacher',
        { url: { id, cle: type, valeur: numero } },
        (result) => ({
          type: RATTACHER_DOCUMENTS,
          data: result,
          numero,
          typeDocument: type
        }),
        null,
        'Le document a été correctement rattaché.'
      )
    );
  };
}

export function detacherDocument(id, type, numero) {
  return (dispatch) => {
    dispatch(
      fetchApiAction(
        'documents:detacher',
        { url: { id, cle: type, valeur: numero } },
        (result) => ({
          type: DETACHER_DOCUMENTS,
          data: result,
          numero,
          typeDocument: type
        }),
        null,
        'Le document a été correctement rattaché.'
      )
    );
  };
}
